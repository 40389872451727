import {React,useState, useEffect } from "react";
import {Modal, ButtonGroup, DropdownButton, Dropdown, Container, Button} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import config from '../config/aws';
import "../styles/vehicles.css";
import { trackPromise } from 'react-promise-tracker';
import MaterialTable, {MTableToolbar} from "@material-table/core";
import { GrEdit, GrFormView,  GrFormTrash} from "react-icons/gr";
import {materialTableLocalization, materialTableOptions} from '../config/materialTable';
import { Auth } from 'aws-amplify';

function Vehicles() {
  const t = useTranslation()[0];
  const [vehicles, updateVehicles] = useState([]);
  const [showVehicleDeletionModal, setShowVehicleDeletionModal] = useState(false);
  const [showUpdatedStockModal, setSupdatedStockModal] = useState(false);
  const [vehicleIdToDelete, setVehicleIdToDelete] = useState('');
  const [vehicleStockTypeToDelete, setvehicleStockTypeToDelete] = useState('');
  const [bearerToken, setBearerToken] = useState();
  const [loading, updateLoading] = useState(true);
  const navigate = useNavigate();

  const vehicleStocksList ={
    "used_vehicles" : t('vehicles.usedVehicles'),
    "demo_vehicles" : t('vehicles.demoVehicles'),
    "new_vehicles" : t('vehicles.newVehicles'),
    "workshop_vehicles" : t('vehicles.workshopVehicles')
  };

  useEffect(() => {
    updateLoading(true);
    trackPromise(
      Auth.currentSession()
      .then(newAuthData => {
        setBearerToken("Bearer " + newAuthData.idToken.jwtToken);
        updateLoading(false);
      })
      .catch(err => console.log(err))
    );
  }, []);

  useEffect(() => {
    if(bearerToken)//Wait for bearer token
    {
      updateLoading(true);
      trackPromise(
      axios.get(`${ config.BASE_URL }/vehicles`,
      {headers: {
          Authorization: bearerToken
        }
      })
      .then((response) => {
        //var unsortedVehicles = response.data.Items;
        //unsortedVehicles.sort(dynamicSort("fileNumber"));
        updateVehicles(response.data.Items);
        updateLoading(false);
      })
      .catch(function (error) {
        console.log('Error:' +  error.message);
      }));
    }
    
}, [bearerToken,showVehicleDeletionModal]);

  function vehicleDeletionModalClose(){
    setShowVehicleDeletionModal(false);
  }

  function vehicleDeletionModalShow(vehicleStockType, vehicleId){
    setShowVehicleDeletionModal(true);
    setvehicleStockTypeToDelete(vehicleStockType);
    setVehicleIdToDelete(vehicleId);
  }

  function updateStockAutomatically(){
    updateLoading(true);
    trackPromise(
    axios.post(`${ config.BASE_URL }/updateVehicleStocks`,'',
    {headers: {
        Authorization: bearerToken
      }
    })
    .then((response) => {
      if(response.data === "vehicles stock successfully updated!")
      {
        updateLoading(false);
        setSupdatedStockModal(true);
      }
    })
    .catch(function (error) {
      console.log('Error:' +  error.message);
    }));
  }

  function updatedStockModalClose(){
    setSupdatedStockModal(false);
  }

  function deleteVehicle(){
        axios.delete(`${ config.BASE_URL }/vehicles/${ vehicleStockTypeToDelete }/${ vehicleIdToDelete }`,
        {headers: {
            Authorization: bearerToken
          }
        })
        .then((response) => {
          console.log(response);
        })
        .catch(function (error) {
          console.log('Error:' +  error.message);
        });
        setvehicleStockTypeToDelete('');
        setVehicleIdToDelete('');

        vehicleDeletionModalClose();
  }

  const columns = [
    { title: t('vehicles.stock'), field: "SK", 
    render: rowData => {
      return vehicleStocksList[rowData.SK.split("#")[2]];
    } },
    { title: t('vehicles.fileNumber'), field: "fileNumber" },
    { title: t('vehicles.brand'), field: "brand" },
    { title: t('vehicles.model'), field: "model" },
    { title: t('vehicles.version'), field: "version" },
    //{ title: t('vehicles.associatedThingName'), field: "associatedThingName" },
    { title: t('vehicles.color'), field: "color" },
    { title: t('vehicles.plateNumber'), field: "plateNumber" },
    { title: t('vehicles.price'), field: "price" },
    { title: t('vehicles.energy'), field: "energy" },
    { title: t('vehicles.gearBox'), field: "gearBox" },
    { title: t('vehicles.mileage'), field: "mileage" },
  ];

  return (
      !loading &&
      <Container fluid>
        <MaterialTable
        title= {t('vehicles.vehicleList')}
        components={{
          Toolbar: props => (
            <div>
              <MTableToolbar {...props} />
              <ButtonGroup className="ml-3">
                  <DropdownButton as={ButtonGroup}  title={t('vehicles.actions')} id="bg-nested-dropdown">
                    <Dropdown.Item  as={Link} to="/new_vehicle">{t('vehicles.createNewVehicle')}</Dropdown.Item>
                    <Dropdown.Item as={Button} onClick={updateStockAutomatically.bind()}>{t('vehicles.updateStockAutomatically')}</Dropdown.Item>
                  </DropdownButton>
                </ButtonGroup>
            </div>
          ),
        }} 
        columns={columns} 
        data={vehicles}
        localization={materialTableLocalization}
        options={materialTableOptions}
        actions={[
          {
            icon: () => <GrFormView />,
            tooltip: t('vehicles.display'),
            onClick: (event, rowData) => {
              navigate(`/vehicles/${ rowData.SK.split("#")[2] }/${ rowData.SK.split("#")[3] }`);
            },
          },
          {
            icon: () => <GrEdit />,
            tooltip: t('vehicles.modify'),
            onClick: (event, rowData) => {
              navigate(`/vehicles/${ rowData.SK.split("#")[2] }/${ rowData.SK.split("#")[3] }/modify`);
            },
          },
          {
            icon: () => <GrFormTrash />,
            tooltip: t('vehicles.delete'),
            onClick: (event, rowData) => {
              vehicleDeletionModalShow(rowData.SK.split("#")[2],rowData.SK.split("#")[3]);
            },
          },
        ]}
          />

        <Modal show={showVehicleDeletionModal} onHide={vehicleDeletionModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('vehicles.deleteConfirmationTitle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('vehicles.deleteConfirmationMessage')}</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={vehicleDeletionModalClose}>
              {t('vehicles.no')}
            </Button>
            <Button variant="success" onClick={deleteVehicle}>
              {t('vehicles.yes')}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showUpdatedStockModal} onHide={updatedStockModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('vehicles.success')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('vehicles.stockUpdateSuccess')}</Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={updatedStockModalClose}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

      </Container>
    )
  }

export default Vehicles;