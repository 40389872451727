import {React, useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from '../config/aws';
import {Container} from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';

function DefaultPoster(){
    var  { elabelStockType,elabelId }  = useParams();//read path params
    const [elabelImage, updateElabelImage] = useState([]);
    var bearerToken = "Bearer " + localStorage.getItem('webIdToken');
    const [loading, updateLoading] = useState(true);

    useEffect(() => {
        updateLoading(true);
        trackPromise(
        axios.get(`${ config.BASE_URL }/getElabelImage/${ elabelStockType }/${ elabelId }`,
        {headers: {
            Authorization: bearerToken
          }
        })
        .then((response) => {
            updateElabelImage(response.data);
            updateLoading(false);
        })
        .catch(function (error,response) {
          console.log('Error:' +  error.message + "  " + response);
        }));
    }, [bearerToken,elabelStockType,elabelId]);

    return(
        !loading &&
        <Container fluid >
            <img src={`data:image/bmp;base64,${elabelImage}`} alt="" width="1600px" height="1200px" />
       </Container>   
    )
}

export default DefaultPoster;