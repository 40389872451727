import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import { usePromiseTracker } from "react-promise-tracker";
import {HashLoader} from "react-spinners";

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress &&
        <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <HashLoader color={"#2D5AEC"} size={100} />
    </div>
   );  
}

ReactDOM.render(
  <React.StrictMode>
      <React.Suspense fallback={<HashLoader color={"#2D5AEC"} size={100} />}>
        <App />
      </React.Suspense>
    <LoadingIndicator/>
  </React.StrictMode>,
  document.getElementById('root')
);
