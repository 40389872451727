import {React, useState, useEffect} from "react";
import {Table, Tabs, Tab, Alert, Button, Container} from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import config from '../config/aws';
import { trackPromise } from 'react-promise-tracker';
import { BsChevronLeft } from "react-icons/bs";
import { Auth } from 'aws-amplify';

function DisplayVehicle(){
    const t = useTranslation()[0];
    let  { vehicleStockType,vehicleId }  = useParams();//read path params
    const [vehicle, updateVehicle] = useState([]);
    const [bearerToken, setBearerToken] = useState();
    const [loading, updateLoading] = useState(true);
    const navigate = useNavigate();

    const vehicleStocksList ={
      "used_vehicles" : t('vehicles.usedVehicles'),
      "demo_vehicles" : t('vehicles.demoVehicles'),
      "new_vehicles" : t('vehicles.newVehicles'),
      "workshop_vehicles" : t('vehicles.workshopVehicles')
    };

    useEffect(() => {
      updateLoading(true);
      trackPromise(
        Auth.currentSession()
        .then(newAuthData => {
          setBearerToken("Bearer " + newAuthData.idToken.jwtToken);
          updateLoading(false);
        })
        .catch(err => console.log(err))
      );
    }, []);

    useEffect(() => {
        if(bearerToken)//Wait for bearer token
        {
          updateLoading(true);
          trackPromise(
            axios.get(`${ config.BASE_URL }/vehicles/${ vehicleStockType }/${ vehicleId }`,
            {headers: {
                Authorization: bearerToken
              }
            })
            .then((response) => {
              updateVehicle(response.data.Item);
              updateLoading(false);
            })
            .catch(function (error,response) {
              console.log('Error:' +  error.message + "  " + response);
            }));
        }
    }, [bearerToken,vehicleStockType,vehicleId])

    return(
    !loading &&
    <Container  fluid >
      <Button variant={'primary'} onClick={() => navigate("/vehicles")}><BsChevronLeft /></Button>
      <Tabs defaultActiveKey="informations" id="uncontrolled-tab-example">
          <Tab eventKey="informations" title={t('vehicles.informations')}>

            <Alert variant={'primary'} className="d-flex justify-content-center">{t('vehicles.identification')}</Alert>

            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>{t('vehicles.stock')}</th>
                  <th>{t('vehicles.fileNumber')}</th>
                  <th>{t('vehicles.vin')}</th>
                  <th>{t('vehicles.vehicleType')}</th>
                  <th>{t('vehicles.plateNumber')}</th>
                </tr>
                <tr>
                  <td>{vehicle.SK && vehicleStocksList[vehicle.SK.split("#")[2]]/*=vehicleStockType*/}</td>
                  <td>{vehicle.fileNumber}</td>
                  <td>{vehicle.vin}</td>
                  <td>{vehicle.vehicleType}</td>
                  <td>{vehicle.plateNumber}</td>
                </tr>
              </tbody>
            </Table>

            <Alert variant={'primary'} className="d-flex justify-content-center">{t('vehicles.informations')}</Alert>

            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>{t('vehicles.firstRegistrationDate')}</th>
                  <th>{t('vehicles.lastRegistrationDate')}</th>
                  <th>{t('vehicles.description')}</th>
                </tr>
                <tr>
                  <td>{vehicle.firstRegistrationDate? (new Date(vehicle.firstRegistrationDate)).toLocaleDateString('fr-FR') : null}</td>
                  <td>{vehicle.lastRegistrationDate? (new Date(vehicle.lastRegistrationDate)).toLocaleDateString('fr-FR') : null}</td>
                  <td>{vehicle.description}</td>
                </tr>
              </tbody>
            </Table>

            <Alert variant={'primary'} className="d-flex justify-content-center">{t('vehicles.description')}</Alert>

            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>{t('vehicles.title')}</th>
                  <th>{t('vehicles.brand')}</th>
                  <th>{t('vehicles.model')}</th>
                  <th>{t('vehicles.price')}</th>
                  <th>{t('vehicles.origin')}</th>
                  <th>{t('vehicles.year')}</th>
                  <th>{t('vehicles.warranty')}</th>
                  <th>{t('vehicles.stockEntryDate')}</th>
                </tr>
                <tr>
                  <td>{vehicle.title}</td>
                  <td>{vehicle.brand}</td>
                  <td>{vehicle.model}</td>
                  <td>{vehicle.price}</td>
                  <td>{vehicle.origin}</td>
                  <td>{vehicle.year}</td>
                  <td>{vehicle.warranty}</td>
                  <td>{vehicle.stockEntryDate? (new Date(vehicle.stockEntryDate)).toLocaleDateString('fr-FR') : null}</td>
                </tr>
                <tr>
                  <th>{t('vehicles.version')}</th>
                  <th>{t('vehicles.color')}</th>
                  <th>{t('vehicles.horsePower')}</th>
                  <th>{t('vehicles.taxPower')}</th>
                  <th>{t('vehicles.doors')}</th>
                  <th>{t('vehicles.seats')}</th>
                  <th>{t('vehicles.mileage')}</th>
                </tr>
                <tr>
                  <td>{vehicle.version}</td>
                  <td>{vehicle.color}</td>
                  <td>{vehicle.horsePower}</td>
                  <td>{vehicle.taxPower}</td>
                  <td>{vehicle.doors}</td>
                  <td>{vehicle.seats}</td>
                  <td>{vehicle.mileage}</td>
                </tr>
                <tr>
                  <th>{t('vehicles.mileageType')}</th>
                  <th>{t('vehicles.saleStatus')}</th>
                  <th>{t('vehicles.critAir')}</th>
                  <th>{t('vehicles.destination')}</th>
                  <th>{t('vehicles.emissions')}</th>
                </tr>
                <tr>
                  <td>{vehicle.mileageType}</td>
                  <td>{vehicle.saleStatus}</td>
                  <td>{vehicle.critAir}</td>
                  <td>{vehicle.destination}</td>
                  <td>{vehicle.emissions}</td>
                </tr>
              </tbody>
            </Table>

            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>{t('vehicles.consumption')}</th>
                  <th>{t('vehicles.gearBox')}</th>
                  <th>{t('vehicles.body')}</th>
                  <th>{t('vehicles.energy')}</th>
                </tr>
                <tr>
                  <td>{vehicle.consumption}</td>
                  <td>{vehicle.gearBox}</td>
                  <td>{vehicle.body}</td>
                  <td>{vehicle.energy}</td>
                </tr>
              </tbody>
            </Table>

            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>{t('vehicles.equipments')}</th>
                  <th>{t('vehicles.options')}</th>
                </tr>
                <tr>
                  <td>{vehicle.equipments.toString()}</td>
                  <td>{vehicle.options}</td>
                </tr>
              </tbody>
            </Table>

          </Tab>
          <Tab eventKey="pictures" title={t('vehicles.pictures')}>
            {vehicle.images && vehicle.images.map((image) => (
              <img src={image} width="500" height="600" alt={t('vehicles.vehiclePicture')}></img>
            ))}      
          </Tab>
      </Tabs>
    </Container>
    )
}

export default DisplayVehicle;