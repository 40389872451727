import {React, useState, useEffect} from "react";
import {Container} from 'react-bootstrap';
import { Auth } from 'aws-amplify';

function Dashboard() {

  const [attributes, setAttributes] = useState([]);
    
  useEffect( () => { 
      async function getUser() {
          try {
            let user = await Auth.currentAuthenticatedUser();
            setAttributes(user.attributes);
          } catch (err) {
              console.log(err);
          }
      }
      getUser();
  }, []);

    return (
      <Container fluid>
        <h1>Bonjour {attributes["given_name"]} {attributes["family_name"]}</h1>
        <p>Bienvenue dans votre espace concession {attributes["custom:dealer"]}</p>
      </Container>
    )
  }

export default Dashboard;