
var materialTableLocalization={
    writable : true,
    pagination: {
        labelDisplayedRows: 'tables.labelDisplayedRows',
        labelRowsSelect: 'tables.labelRowsSelect',
        labelRowsPerPage: 'tables.labelRowsPerPage',
        firstAriaLabel: 'tables.firstAriaLabel',
        firstTooltip: 'tables.firstTooltip',
        previousAriaLabel: 'tables.previousAriaLabel',
        previousTooltip: 'tables.previousTooltip',
        nextAriaLabel: 'tables.nextAriaLabel',
        nextTooltip: 'tables.nextTooltip',
        lastAriaLabel: 'tables.lastAriaLabel',
        lastTooltip: 'tables.lastTooltip',
    },
    toolbar: {
      searchTooltip: 'tables.search',
      searchPlaceholder: 'tables.search'
    },
    body:{
      emptyDataSourceMessage: 'tables.emptyDataSourceMessage',
      filterRow:{
        filterTooltip: 'tables.filter',
        filterPlaceHolder: 'tables.filter'
      }
    }
};

var materialTableOptions={
    pageSize: 10,
    filtering: true,
    idSynonym: 'SK'
};

export {materialTableLocalization,materialTableOptions};