import {React, useState, useEffect} from "react";
import {Col, Table, Alert, Container, Form, Button, Row} from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import config from '../config/aws';
import { trackPromise } from 'react-promise-tracker';
import { BsChevronLeft } from "react-icons/bs";
import { Auth } from 'aws-amplify';

var emptyVehicle ={
  fileNumber:  '',
  vin:  '',
  vehicletype:  '',
  plateNumber:  '',
  firstRegistrationDate:  '',
  lastRegistrationDate:  '',
  description:  '',
  brand:  '',
  model:  '',
  price:  '',
  origin:  '',
  warranty:  '',
  stockEntryDate:  '',
  version:  '',
  color:  '',
  horsePower:  '',
  taxPower:  '',
  doors:  '',
  seats:  '',
  mileage:  '',
  mileageType:  '',
  saleStatus:  '',
  critAir:  '',
  destination:  '',
  emissions:  '',
  consumption:  '',
  gearBox:  '',
  body:  '',
  equipments:  '',
  options:  ''
};

function AssociateElabel(){
    const t = useTranslation()[0];
    const [validated, setValidated] = useState(false);
    const [vehicles, updateVehicles] = useState([]);
    var  { elabelStockType,elabelId }  = useParams();//read path params
    const [elabel, updateElabel] = useState([]);
    const [currentVehicleStockType, updateCurrentVehicleStockType] = useState(elabelStockType);
    const [vehicle, updateVehicle] = useState({});
    const navigate = useNavigate();
    const [bearerToken, setBearerToken] = useState();
    const [loading, updateLoading] = useState(true);

    const vehicleStocksList ={
      "used_vehicles" : t('vehicles.usedVehicles'),
      "demo_vehicles" : t('vehicles.demoVehicles'),
      "new_vehicles" : t('vehicles.newVehicles'),
      "workshop_vehicles" : t('vehicles.workshopVehicles')
    };

    useEffect(() => {
      updateLoading(true);
      trackPromise(
        Auth.currentSession()
        .then(newAuthData => {
          setBearerToken("Bearer " + newAuthData.idToken.jwtToken);
          updateLoading(false);
        })
        .catch(err => console.log(err))
      );
    }, []);

    useEffect(() => {
        if(bearerToken)//Wait for bearer token
        {
          updateLoading(true);
        trackPromise(
          //Get elabel data
          axios.get(`${ config.BASE_URL }/elabels/${ elabelStockType }/${ elabelId }`,
          {headers: {
              Authorization: bearerToken
            }
          })
          .then((response) => {
              updateElabel(response.data.Item);
              var receivedElabel = response.data.Item;

              //Get list of vehicles from the current selected stock type
              axios.get(`${ config.BASE_URL }/vehicles/${ currentVehicleStockType }`,
              {headers: {
                  Authorization: bearerToken
                }
              })
              .then((response) => {
                updateVehicles(response.data.Items);
                var receivedVehicles = response.data.Items;
                
                //Find currently associated vehicle
                var newVehicleAssigned = receivedVehicles.find((vehicle) => (
                  vehicle.SK.split("#")[3] === receivedElabel.associatedVehicleId
                ));
          
                //Display associated vehicle data. If there is no matching vehicle, display an "empty vehicle"
                updateVehicle(newVehicleAssigned? newVehicleAssigned : emptyVehicle);

                updateLoading(false);
              })
              .catch(function (error) {
                console.log('Error: ' +  error.message);
              });
          })
          .catch(function (error,response) {
            console.log('Error:' +  error.message + "  " + response);
          })
          );
        }
    }, [bearerToken,elabelStockType,currentVehicleStockType,elabelId]);

const handleChange= ((event) => {

      //Find selected vehicle
      var newVehicleAssigned = vehicles.find((vehicle) => (
        vehicle.SK.split("#")[3] === event.currentTarget.value
      ));

      //Display currently selected vehicle data. If there is no matching vehicle, display an "empty vehicle"
      updateVehicle(newVehicleAssigned? newVehicleAssigned : emptyVehicle);
    });

    const handleFormStockTypeChange= ((event) => {
      updateCurrentVehicleStockType(event.currentTarget.value);
    });

    const handleSubmit = (event) => {
      const form = event.currentTarget;

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
      }
      else {
        event.preventDefault();
        event.stopPropagation();

        var elabelToModify = elabel;

        elabelToModify.SK =  `${ elabel.SK.split("#")[0] }#${ elabel.SK.split("#")[1] }#${ form.stockType.value }#${ elabel.SK.split("#")[3] }`;
        
        elabelToModify.associatedVehicleId = form.associatedVehicleId.value;
        if(elabelToModify.associatedVehicleId !== "noVehicleAssigned")
        {
          elabelToModify.associatedVehicleFileNumber = vehicle.fileNumber;
        }
        else
        {
          elabelToModify.associatedVehicleFileNumber = "noVehicleAssigned";
        }

        axios.put(`${ config.BASE_URL }/elabels/${ elabelStockType }/${ elabelId }`, elabelToModify,
        {headers: {
            Authorization: bearerToken
          }
        })
        .then((response) => {
          console.log(response);
        })
        .catch(function (error) {
          console.log('Error:' +  error.message);
        });

        setValidated(true);
        navigate("/elabels");
      }        
    };


    return(
        !loading &&
        <Container fluid>
            <Button variant={'primary'} onClick={() => navigate("/elabels")}><BsChevronLeft /></Button>
            <Alert variant={'success'} className="d-flex justify-content-center mt-5">{t('elabels.elabelAttributes')}</Alert>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group as={Row}>
                  <Form.Group as={Col} md="6" controlId="stockType">
                    <Form.Label>{t('elabels.stock')}</Form.Label>
                    {
                      elabel.SK &&

                      <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        required
                        onChange={handleFormStockTypeChange}
                        type="text"
                        defaultValue={currentVehicleStockType}//{elabel.SK.split("#")[2]}
                      >
                        <option value="used_vehicles">{vehicleStocksList["used_vehicles"]}</option>
                        <option value="demo_vehicles">{vehicleStocksList["demo_vehicles"]}</option>
                        <option value="new_vehicles">{vehicleStocksList["new_vehicles"]}</option>
                        <option value="workshop_vehicles">{vehicleStocksList["workshop_vehicles"]}</option>
                      </Form.Control>
                    }
                    
                    <Form.Control.Feedback type="invalid">{t('elabels.provideValidState')}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="6" controlId="associatedVehicleId">
                    <Form.Label>{t('elabels.associatedVehicle')}</Form.Label>
                    {
                      elabel.associatedVehicleId &&

                      <Form.Control
                        as="select"
                        className="my-1 mr-sm-2"
                        required
                        type="text"
                        onChange={handleChange}
                        defaultValue={ elabel.associatedVehicleId }
                      >     
                            {vehicles && 
                            <option value="noVehicleAssigned"></option>}
                            {vehicles && vehicles.map((vehicle) => (
                                <option key={vehicle.SK.split("#")[3]} value={vehicle.SK.split("#")[3]}>{vehicle.fileNumber}</option> 
                            ))}                 
                      </Form.Control>
                    }
                    
                    <Form.Control.Feedback type="invalid">{t('elabels.provideValidState')}</Form.Control.Feedback>
                  </Form.Group>
              </Form.Group>
              <Button type="submit">{t('elabels.save')}</Button>
            </Form>
            
          <Alert variant={'danger'} className="d-flex justify-content-center ">{t('elabels.vehicleAttributes')}</Alert>
          
          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>{t('vehicles.stock')}</th>
                <th>{t('vehicles.fileNumber')}</th>
                <th>{t('vehicles.vin')}</th>
                <th>{t('vehicles.vehicleType')}</th>
              </tr>
              <tr>
                <td>{vehicle.SK && vehicleStocksList[vehicle.SK.split("#")[2]]/*=vehicleStockType*/}</td>
                <td>{vehicle.fileNumber}</td>
                <td>{vehicle.vin}</td>
                <td>{vehicle.vehicleType}</td>
              </tr>
            </tbody>
          </Table>
          
          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>{t('vehicles.firstRegistrationDate')}</th>
                <th>{t('vehicles.lastRegistrationDate')}</th>
                <th>{t('vehicles.description')}</th>
              </tr>
              <tr>
                  <td>{vehicle.firstRegistrationDate? (new Date(vehicle.firstRegistrationDate)).toLocaleDateString('fr-FR') : null}</td>
                  <td>{vehicle.lastRegistrationDate? (new Date(vehicle.lastRegistrationDate)).toLocaleDateString('fr-FR') : null}</td>
                  <td>{vehicle.description}</td>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>{t('vehicles.brand')}</th>
                <th>{t('vehicles.model')}</th>
                <th>{t('vehicles.price')}</th>
                <th>{t('vehicles.origin')}</th>
                <th>{t('vehicles.warranty')}</th>
                <th>{t('vehicles.stockEntryDate')}</th>
              </tr>
              <tr>
                <td>{vehicle.brand}</td>
                <td>{vehicle.model}</td>
                <td>{vehicle.price}</td>
                <td>{vehicle.origin}</td>
                <td>{vehicle.warranty}</td>
                <td>{vehicle.stockEntryDate? (new Date(vehicle.stockEntryDate)).toLocaleDateString('fr-FR') : null}</td>
              </tr>
              <tr>
                <th>{t('vehicles.version')}</th>
                <th>{t('vehicles.color')}</th>
                <th>{t('vehicles.horsePower')}</th>
                <th>{t('vehicles.taxPower')}</th>
                <th>{t('vehicles.doors')}</th>
                <th>{t('vehicles.seats')}</th>
              </tr>
              <tr>
                <td>{vehicle.version}</td>
                <td>{vehicle.color}</td>
                <td>{vehicle.horsePower}</td>
                <td>{vehicle.taxPower}</td>
                <td>{vehicle.doors}</td>
                <td>{vehicle.seats}</td>
              </tr>
              <tr>
                <th>{t('vehicles.mileage')}</th>
                <th>{t('vehicles.mileageType')}</th>
                <th>{t('vehicles.saleStatus')}</th>
                <th>{t('vehicles.critAir')}</th>
                <th>{t('vehicles.destination')}</th>
                <th>{t('vehicles.emissions')}</th>
              </tr>
              <tr>
                <td>{vehicle.mileage}</td>
                <td>{vehicle.mileageType}</td>
                <td>{vehicle.saleStatus}</td>
                <td>{vehicle.critAir}</td>
                <td>{vehicle.destination}</td>
                <td>{vehicle.emissions}</td>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>{t('vehicles.consumption')}</th>
                <th>{t('vehicles.gearBox')}</th>
                <th>{t('vehicles.body')}</th>
                <th>{t('vehicles.energy')}</th>
              </tr>
              <tr>
                <td>{vehicle.consumption}</td>
                <td>{vehicle.gearBox}</td>
                <td>{vehicle.body}</td>
                <td>{vehicle.energy}</td>
              </tr>
            </tbody>
          </Table>

          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>{t('vehicles.equipments')}</th>
                <th>{t('vehicles.options')}</th>
              </tr>
              <tr>
                <td>{vehicle.equipments}</td>
                <td>{vehicle.options}</td>
              </tr>
            </tbody>
          </Table>
          
        </Container>
    )
}

export default AssociateElabel;