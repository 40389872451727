import {React, useState, useEffect} from "react";
import {Alert, Form, Col, Button, Row, Container} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import config from '../config/aws';
import { BsChevronLeft } from "react-icons/bs";
import { Auth } from 'aws-amplify';

function NewVehicle() {
    const t = useTranslation()[0];
    const [validated, setValidated] = useState(false);
    const [bearerToken, setBearerToken] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        Auth.currentSession()
        .then(newAuthData => {
          setBearerToken("Bearer " + newAuthData.idToken.jwtToken);
        })
        .catch(err => console.log(err))
    }, []);

    const vehicleStocksList ={
      "used_vehicles" : t('vehicles.usedVehicles'),
      "demo_vehicles" : t('vehicles.demoVehicles'),
      "new_vehicles" : t('vehicles.newVehicles'),
      "workshop_vehicles" : t('vehicles.workshopVehicles')
    };

    var newVehicle ={
      fileNumber:  '',
      vin:  '',
      vehicletype:  '',
      plateNumber:  '',
      firstRegistrationDate:  '',
      lastRegistrationDate:  '',
      description:  '',
      brand:  '',
      model:  '',
      price:  '',
      origin:  '',
      warranty:  '',
      stockEntryDate:  '',
      version:  '',
      color:  '',
      horsePower:  '',
      taxPower:  '',
      doors:  '',
      seats:  '',
      mileage:  '',
      mileageType:  '',
      saleStatus:  '',
      critAir:  '',
      destination:  '',
      emissions:  '',
      consumption:  '',
      gearBox:  '',
      body:  '',
      equipments:  '',
      options:  ''
    };
    
    function assignVehicle(filledForm){
      newVehicle.fileNumber             = filledForm.fileNumber.value;
      newVehicle.vin                    = filledForm.vin.value;
      newVehicle.vehicleType            = filledForm.vehicleType.value;
      newVehicle.plateNumber            = filledForm.plateNumber.value;
      newVehicle.firstRegistrationDate  = filledForm.firstRegistrationDate.value;
      newVehicle.lastRegistrationDate   = filledForm.lastRegistrationDate.value;
      newVehicle.description            = filledForm.description.value;
      newVehicle.brand                  = filledForm.brand.value;
      newVehicle.model                  = filledForm.model.value;
      newVehicle.price                  = filledForm.price.value;
      newVehicle.origin                 = filledForm.origin.value;
      newVehicle.year                   = filledForm.year.value;
      newVehicle.warranty               = filledForm.warranty.value;
      newVehicle.stockEntryDate         = filledForm.stockEntryDate.value;
      newVehicle.version                = filledForm.version.value;
      newVehicle.color                  = filledForm.color.value;
      newVehicle.horsePower             = filledForm.horsePower.value;
      newVehicle.taxPower               = filledForm.taxPower.value;
      newVehicle.doors                  = filledForm.doors.value;
      newVehicle.seats                  = filledForm.seats.value;
      newVehicle.mileage                = filledForm.mileage.value;
      newVehicle.mileageType            = filledForm.mileageType.value;
      newVehicle.saleStatus             = filledForm.saleStatus.value;
      newVehicle.critAir                = filledForm.critAir.value;
      newVehicle.destination            = filledForm.destination.value;
      newVehicle.emissions              = filledForm.emissions.value;
      newVehicle.consumption            = filledForm.consumption.value;
      newVehicle.gearBox                = filledForm.gearBox.value;
      newVehicle.body                   = filledForm.body.value;
      newVehicle.energy                 = filledForm.energy.value;
      newVehicle.equipments             = filledForm.equipments.value.split(",");
      newVehicle.options                = filledForm.options.value;
    }

    const handleSubmit = (event) => {
      const form = event.currentTarget;

      setValidated(true);

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        
      }
      else {
        event.preventDefault();
        event.stopPropagation();

        assignVehicle(form);

        console.log(newVehicle);

        axios.post(`${ config.BASE_URL }/vehicles/${ form.stockType.value }/noId`, newVehicle,
        {headers: {
            Authorization: bearerToken
          }
        })
        .then((response) => {
          console.log(response);
        })
        .catch(function (error) {
          console.log('Error:' +  error.message);
        });

        navigate("/vehicles");
      }      
    };
  
    return (
      <Container  fluid >
        <Button variant={'primary'} onClick={() => navigate("/vehicles")}><BsChevronLeft /></Button>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <h1>{t('vehicles.vehicleCreation')}</h1>
          <Alert variant={'primary'} className="d-flex justify-content-center">{t('vehicles.identification')}</Alert>

          <Form.Group as={Row}>
              <Form.Group as={Col} md="2" controlId="stockType">
                  <Form.Label>{t('vehicles.stock')}</Form.Label>
                  <Form.Control
                      as="select"
                      className="my-1 mr-sm-2"
                      required
                      type="text"
                  >
                      <option value="used_vehicles">{vehicleStocksList["used_vehicles"]}</option>
                      <option value="demo_vehicles">{vehicleStocksList["demo_vehicles"]}</option>
                      <option value="new_vehicles">{vehicleStocksList["new_vehicles"]}</option>
                      <option value="workshop_vehicles">{vehicleStocksList["workshop_vehicles"]}</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                  {t('vehicles.provideValidState')}
                  </Form.Control.Feedback>
              </Form.Group>

            <Form.Group as={Col} md="3" controlId="fileNumber">
              <Form.Label>{t('vehicles.fileNumber')}</Form.Label>
              <Form.Control
                required
                type="text"
              />
              <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="vin">
              <Form.Label>{t('vehicles.vin')}</Form.Label>
              <Form.Control
                required
                type="text"
              />
              <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="2" controlId="vehicleType">
              <Form.Label>{t('vehicles.vehicleType')}</Form.Label>
              <Form.Control
                required
                type="text"
              />
              <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="2" controlId="plateNumber">
                  <Form.Label>{t('vehicles.plateNumber')}</Form.Label>
                  <Form.Control
                  required
                  type="text"
                  />
                  <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
            </Form.Group>

          </Form.Group>

          <Alert variant={'primary'} className="d-flex justify-content-center">{t('vehicles.informations')}</Alert>

          <Form.Group as={Row}>

              <Form.Group as={Col} md="3" controlId="firstRegistrationDate">
                  <Form.Label>{t('vehicles.firstRegistrationDate')}</Form.Label>
                  <Form.Control
                  type="date"
                  />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="lastRegistrationDate">
                  <Form.Label>{t('vehicles.lastRegistrationDate')}</Form.Label>
                  <Form.Control
                  type="date"
                  />
                  <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group as={Col} md="6" controlId="description">
                  <Form.Label>{t('vehicles.description')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>
          </Form.Group>

          <Alert variant={'primary'} className="d-flex justify-content-center">{t('vehicles.description')}</Alert>

          <Form.Group as={Row}>

              <Form.Group as={Col} md="2" controlId="brand">
                  <Form.Label>{t('vehicles.brand')}</Form.Label>
                  <Form.Control
                  required
                  type="text"
                  />
                  <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="model">
                  <Form.Label>{t('vehicles.model')}</Form.Label>
                  <Form.Control
                  required
                  type="text"
                  />
                  <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="price">
                  <Form.Label>{t('vehicles.price')}</Form.Label>
                  <Form.Control
                  required
                  type="text"
                  />
                  <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="origin">
                  <Form.Label>{t('vehicles.origin')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="year">
                  <Form.Label>{t('vehicles.year')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="warranty">
                  <Form.Label>{t('vehicles.warranty')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="stockEntryDate">
                  <Form.Label>{t('vehicles.stockEntryDate')}</Form.Label>
                  <Form.Control
                  type="date"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="version">
                  <Form.Label>{t('vehicles.version')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="color">
                  <Form.Label>{t('vehicles.color')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="horsePower">
                  <Form.Label>{t('vehicles.horsePower')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="taxPower">
                  <Form.Label>{t('vehicles.taxPower')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="doors">
                  <Form.Label>{t('vehicles.doors')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="seats">
                  <Form.Label>{t('vehicles.seats')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="mileage">
                  <Form.Label>{t('vehicles.mileage')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="mileageType">
                  <Form.Label>{t('vehicles.mileageType')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="saleStatus">
                  <Form.Label>{t('vehicles.saleStatus')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="critAir">
                  <Form.Label>{t('vehicles.critAir')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>
              
              <Form.Group as={Col} md="2" controlId="destination">
                  <Form.Label>{t('vehicles.destination')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="emissions">
                  <Form.Label>{t('vehicles.emissions')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="consumption">
                  <Form.Label>{t('vehicles.consumption')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="gearBox">
                  <Form.Label>{t('vehicles.gearBox')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="body">
                  <Form.Label>{t('vehicles.body')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="energy">
                  <Form.Label>{t('vehicles.energy')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="equipments">
                  <Form.Label>{t('vehicles.equipments')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="options">
                  <Form.Label>{t('vehicles.options')}</Form.Label>
                  <Form.Control
                  type="text"
                  />
              </Form.Group>


          </Form.Group>
          <Button type="submit">{t('vehicles.create')}</Button>
        </Form>
      </Container>
    );
  }
  
export default NewVehicle;