import {React,useState, useEffect} from "react";
import {Container} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import config from '../config/aws';
import "../styles/vehicles.css";
import { trackPromise } from 'react-promise-tracker';
import MaterialTable from "@material-table/core";
import { GrEdit, GrFormView} from "react-icons/gr";
import {materialTableLocalization, materialTableOptions} from '../config/materialTable';
import { Auth } from 'aws-amplify';

function Elabels() {
  const t = useTranslation()[0];
  const [elabels, updateElabels] = useState([]);
  const [bearerToken, setBearerToken] = useState();
  const [loading, updateLoading] = useState(true);
  const navigate = useNavigate();

  const vehicleStocksList ={
    "used_vehicles" : t('vehicles.usedVehicles'),
    "demo_vehicles" : t('vehicles.demoVehicles'),
    "new_vehicles" : t('vehicles.newVehicles'),
    "workshop_vehicles" : t('vehicles.workshopVehicles')
  };

  useEffect(() => {
    updateLoading(true);
    trackPromise(
      Auth.currentSession()
      .then(newAuthData => {
        setBearerToken("Bearer " + newAuthData.idToken.jwtToken);
        updateLoading(false);
      })
      .catch(err => console.log(err))
    );
  }, []);

  useEffect(() => {
    if(bearerToken)//Wait for bearer token
    {
      updateLoading(true);
      trackPromise(
      axios.get(`${ config.BASE_URL }/elabels`,
      {headers: {
          Authorization: bearerToken
        }
      })
      .then((response) => {
        updateElabels(response.data.Items);
        updateLoading(false);
      })
      .catch(function (error) {
        console.log('Error:' +  error.message);
      }));
    }
}, [bearerToken]);

  const columns = [
    { title: t('elabels.stock'), field: "SK", 
      render: rowData => {
        return vehicleStocksList[rowData.SK.split("#")[2]];
      } 
    },
    { title: t('elabels.elabelId'), field: "thingName",
      render: rowData => {
        return rowData.thingName.toUpperCase();
      } 
    },
    { title: t('elabels.associatedVehicle'), field: "associatedVehicleFileNumber",
    render: rowData => {
      return ((rowData.associatedVehicleFileNumber === "noVehicleAssigned")?t('elabels.noVehicleAssigned') : rowData.associatedVehicleFileNumber);
    } 
  },
    { title: t('elabels.status'), field: "status" },
    { title: t('elabels.temperature'), field: "temperature" },
    { title: t('elabels.batteryLeft'), field: "batteryLeft" },
    { title: t('elabels.lastUpdateDate'), field: "lastUpdateDate",
      render: rowData => {
        return (new Date(rowData.lastUpdateDate)).toLocaleString(undefined, { timeZone: 'UTC' });
      } 
    },
  ];

  return (
    !loading &&
    <Container fluid>
    <MaterialTable
      title= {t('elabels.elabelsList')} 
      columns={columns} 
      data={elabels}
      localization={materialTableLocalization}
      options={materialTableOptions}
      actions={[
        {
          icon: () => <GrFormView />,
          tooltip: t('elabels.display'),
          onClick: (event, rowData) => {
            navigate(`/elabels/${ rowData.SK.split("#")[2] }/${ rowData.SK.split("#")[3] }`);
          },
        },
        {
          icon: () => <GrEdit />,
          tooltip: t('elabels.modify'),
          onClick: (event, rowData) => {
            navigate(`/elabels/${ rowData.SK.split("#")[2] }/${ rowData.SK.split("#")[3] }/modify`);
          },
        },
      ]}
        />

    </Container>
    )
  }

export default Elabels;