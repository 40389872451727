import {React, useState, useEffect} from "react";
import {Alert, Form, Col, Button, Row, Container} from 'react-bootstrap';
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import config from '../config/aws';
import { trackPromise } from 'react-promise-tracker';
import { BsChevronLeft } from "react-icons/bs";
import { Auth } from 'aws-amplify';

function ModifyVehicle(){
    const t = useTranslation()[0];
    let  { vehicleStockType,vehicleId }  = useParams();
    const [vehicle, updateVehicle] = useState([]);
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const [bearerToken, setBearerToken] = useState();
    var vehicleToModify;
    const [loading, updateLoading] = useState(true);
    
    const vehicleStocksList ={
      "used_vehicles" : t('vehicles.usedVehicles'),
      "demo_vehicles" : t('vehicles.demoVehicles'),
      "new_vehicles" : t('vehicles.newVehicles'),
      "workshop_vehicles" : t('vehicles.workshopVehicles')
    };
    
    function assignVehicle(filledForm){
        //Copy all id params to vehicle before modifying attributes
        vehicleToModify                        = vehicle;

        vehicleToModify.SK =  `${ vehicle.SK.split("#")[0] }#${ vehicle.SK.split("#")[1] }#${ filledForm.stockType.value }#${ vehicle.SK.split("#")[3] }`;

        vehicleToModify.fileNumber             = filledForm.fileNumber.value;
        vehicleToModify.vin                    = filledForm.vin.value;
        vehicleToModify.vehicleType            = filledForm.vehicleType.value;
        vehicleToModify.plateNumber            = filledForm.plateNumber.value;
        vehicleToModify.firstRegistrationDate  = filledForm.firstRegistrationDate.value;
        vehicleToModify.lastRegistrationDate   = filledForm.lastRegistrationDate.value;
        vehicleToModify.description            = filledForm.description.value;
        vehicleToModify.title                  = filledForm.title.value;
        vehicleToModify.brand                  = filledForm.brand.value;
        vehicleToModify.model                  = filledForm.model.value;
        vehicleToModify.price                  = filledForm.price.value;
        vehicleToModify.origin                 = filledForm.origin.value;
        vehicleToModify.year                   = filledForm.year.value;
        vehicleToModify.warranty               = filledForm.warranty.value;
        vehicleToModify.stockEntryDate         = filledForm.stockEntryDate.value;
        vehicleToModify.version                = filledForm.version.value;
        vehicleToModify.color                  = filledForm.color.value;
        vehicleToModify.horsePower             = filledForm.horsePower.value;
        vehicleToModify.taxPower               = filledForm.taxPower.value;
        vehicleToModify.doors                  = filledForm.doors.value;
        vehicleToModify.seats                  = filledForm.seats.value;
        vehicleToModify.mileage                = filledForm.mileage.value;
        vehicleToModify.mileageType            = filledForm.mileageType.value;
        vehicleToModify.saleStatus             = filledForm.saleStatus.value;
        vehicleToModify.critAir                = filledForm.critAir.value;
        vehicleToModify.destination            = filledForm.destination.value;
        vehicleToModify.emissions              = filledForm.emissions.value;
        vehicleToModify.consumption            = filledForm.consumption.value;
        vehicleToModify.gearBox                = filledForm.gearBox.value;
        vehicleToModify.body                   = filledForm.body.value;
        vehicleToModify.energy                 = filledForm.energy.value;
        vehicleToModify.equipments             = filledForm.equipments.value.split(",");
        vehicleToModify.options                = filledForm.options.value;
    };

    useEffect(() => {
      updateLoading(true);
      trackPromise(
        Auth.currentSession()
        .then(newAuthData => {
          setBearerToken("Bearer " + newAuthData.idToken.jwtToken);
          updateLoading(false);
        })
        .catch(err => console.log(err))
      );
    }, []);

    useEffect(() => {
      if(bearerToken)//Wait for bearer token
      {
        updateLoading(true);
        trackPromise(
          axios.get(`${ config.BASE_URL }/vehicles/${ vehicleStockType }/${ vehicleId }`,
          {headers: {
              Authorization: bearerToken
            }
          })
          .then((response) => {
            updateVehicle(response.data.Item);
            updateLoading(false);
          })
          .catch(function (error,response) {
            console.log('Error:' +  error.message + "  " + response);
          }));
      }
    }, [bearerToken,vehicleStockType,vehicleId])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
  
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
          setValidated(true);
        }
        else {
          event.preventDefault();
          event.stopPropagation();
  
          assignVehicle(form);
  
          axios.put(`${ config.BASE_URL }/vehicles/${ vehicleStockType }/${ vehicleId }`, vehicleToModify,
          {headers: {
              Authorization: bearerToken
            }
          })
          .then((response) => {
            console.log(response);
          })
          .catch(function (error) {
            console.log('Error:' +  error.message);
          });
  
          setValidated(true);
          navigate("/vehicles");
        }        
      };

    return(
        !loading &&
        <Container  fluid >
          <Button variant={'primary'} onClick={() => navigate("/vehicles")}><BsChevronLeft /></Button>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <h1>{t('vehicles.vehicleModification')}</h1>
          <Alert variant={'primary'} className="d-flex justify-content-center">{t('vehicles.identification')}</Alert>

          <Form.Group as={Row}>
              <Form.Group as={Col} md="2" controlId="stockType">
                  <Form.Label>{t('vehicles.stock')}</Form.Label>
                  {
                    vehicle.SK &&

                    <Form.Control
                      as="select"
                      className="my-1 mr-sm-2"
                      required
                      type="text"
                      defaultValue={vehicle.SK.split("#")[2]}
                    >
                      <option value="used_vehicles">{vehicleStocksList["used_vehicles"]}</option>
                      <option value="demo_vehicles">{vehicleStocksList["demo_vehicles"]}</option>
                      <option value="new_vehicles">{vehicleStocksList["new_vehicles"]}</option>
                      <option value="workshop_vehicles">{vehicleStocksList["workshop_vehicles"]}</option>
                    </Form.Control>
                  }
                  
                  <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
              </Form.Group>

            <Form.Group as={Col} md="3" controlId="fileNumber">
              <Form.Label>{t('vehicles.fileNumber')}</Form.Label>
              <Form.Control
                required
                type="text"
                defaultValue={vehicle.fileNumber}
              />
              <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" controlId="vin">
              <Form.Label>{t('vehicles.vin')}</Form.Label>
              <Form.Control
                required
                type="text"
                defaultValue={vehicle.vin}
              />
              <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="2" controlId="vehicleType">
              <Form.Label>{t('vehicles.vehicleType')}</Form.Label>
              <Form.Control
                required
                type="text"
                defaultValue={vehicle.vehicleType}
              />
              <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="2" controlId="plateNumber">
                  <Form.Label>{t('vehicles.plateNumber')}</Form.Label>
                  <Form.Control
                  required
                  type="text"
                  defaultValue={vehicle.plateNumber}
                  />
                  <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
            </Form.Group>

          </Form.Group>

          <Alert variant={'primary'} className="d-flex justify-content-center">{t('vehicles.informations')}</Alert>

          <Form.Group as={Row}>

              <Form.Group as={Col} md="3" controlId="firstRegistrationDate">
                  <Form.Label>{t('vehicles.firstRegistrationDate')}</Form.Label>
                  <Form.Control
                  type="date"
                  defaultValue={vehicle.firstRegistrationDate && vehicle.firstRegistrationDate.substring(0, 10)}
                  />
              </Form.Group>

              <Form.Group as={Col} md="3" controlId="lastRegistrationDate">
                  <Form.Label>{t('vehicles.lastRegistrationDate')}</Form.Label>
                  <Form.Control
                  type="date"
                  defaultValue={vehicle.lastRegistrationDate && vehicle.lastRegistrationDate.substring(0, 10)}
                  />
                  <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
              </Form.Group>
              
              <Form.Group as={Col} md="6" controlId="description">
                  <Form.Label>{t('vehicles.description')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.description}
                  />
              </Form.Group>
          </Form.Group>

          <Alert variant={'primary'} className="d-flex justify-content-center">{t('vehicles.description')}</Alert>

          <Form.Group as={Row}>

              <Form.Group as={Col} md="2" controlId="title">
                  <Form.Label>{t('vehicles.title')}</Form.Label>
                  <Form.Control
                  required
                  type="text"
                  defaultValue={vehicle.title}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="brand">
                  <Form.Label>{t('vehicles.brand')}</Form.Label>
                  <Form.Control
                  required
                  type="text"
                  defaultValue={vehicle.brand}
                  />
                  <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="model">
                  <Form.Label>{t('vehicles.model')}</Form.Label>
                  <Form.Control
                  required
                  type="text"
                  defaultValue={vehicle.model}
                  />
                  <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="price">
                  <Form.Label>{t('vehicles.price')}</Form.Label>
                  <Form.Control
                  required
                  type="text"
                  defaultValue={vehicle.price}
                  />
                  <Form.Control.Feedback type="invalid">{t('vehicles.provideValidState')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="origin">
                  <Form.Label>{t('vehicles.origin')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.origin}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="year">
                  <Form.Label>{t('vehicles.year')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.year}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="warranty">
                  <Form.Label>{t('vehicles.warranty')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.warranty}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="stockEntryDate">
                  <Form.Label>{t('vehicles.stockEntryDate')}</Form.Label>
                  <Form.Control
                  type="date"
                  defaultValue={vehicle.stockEntryDate && vehicle.stockEntryDate.substring(0, 10)}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="version">
                  <Form.Label>{t('vehicles.version')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.version}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="color">
                  <Form.Label>{t('vehicles.color')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.color}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="horsePower">
                  <Form.Label>{t('vehicles.horsePower')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.horsePower}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="taxPower">
                  <Form.Label>{t('vehicles.taxPower')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.taxPower}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="doors">
                  <Form.Label>{t('vehicles.doors')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.doors}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="seats">
                  <Form.Label>{t('vehicles.seats')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.seats}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="mileage">
                  <Form.Label>{t('vehicles.mileage')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.mileage}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="mileageType">
                  <Form.Label>{t('vehicles.mileageType')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.mileageType}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="saleStatus">
                  <Form.Label>{t('vehicles.saleStatus')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.saleStatus}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="critAir">
                  <Form.Label>{t('vehicles.critAir')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.critAir}
                  />
              </Form.Group>
              
              <Form.Group as={Col} md="2" controlId="destination">
                  <Form.Label>{t('vehicles.destination')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.destination}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="emissions">
                  <Form.Label>{t('vehicles.emissions')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.emissions}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="consumption">
                  <Form.Label>{t('vehicles.consumption')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.consumption}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="gearBox">
                  <Form.Label>{t('vehicles.gearBox')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.gearBox}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="body">
                  <Form.Label>{t('vehicles.body')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.body}
                  />
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="energy">
                  <Form.Label>{t('vehicles.energy')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.energy}
                  />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="equipments">
                  <Form.Label>{t('vehicles.equipments')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.equipments}
                  />
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="options">
                  <Form.Label>{t('vehicles.options')}</Form.Label>
                  <Form.Control
                  type="text"
                  defaultValue={vehicle.options}
                  />
              </Form.Group>


          </Form.Group>
          <Button type="submit">{t('vehicles.save')}</Button>
        </Form>
      </Container>
    )
}

export default ModifyVehicle;