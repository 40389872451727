import {React, useState, useEffect} from "react";
import {Table, Alert, Container, Figure, Row, Col, Button} from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import config from '../config/aws';
import { trackPromise } from 'react-promise-tracker';
import { BsChevronLeft } from "react-icons/bs";
import { Auth } from 'aws-amplify';


function DisplayElabel(){
    const t = useTranslation()[0];
    let  { elabelStockType,elabelId }  = useParams();//read path params
    const [elabel, updateElabel] = useState({});
    const [elabelImage, updateElabelImage] = useState([]);
    const navigate = useNavigate();
    const [bearerToken, setBearerToken] = useState();
    const [loading, updateLoading] = useState(true);

    const vehicleStocksList ={
      "used_vehicles" : t('vehicles.usedVehicles'),
      "demo_vehicles" : t('vehicles.demoVehicles'),
      "new_vehicles" : t('vehicles.newVehicles'),
      "workshop_vehicles" : t('vehicles.workshopVehicles')
    };

    useEffect(() => {
      updateLoading(true);
      trackPromise(
        Auth.currentSession()
        .then(newAuthData => {
          setBearerToken("Bearer " + newAuthData.idToken.jwtToken);
          updateLoading(false);
        })
        .catch(err => console.log(err))
      );
    }, []);

    useEffect(() => {
        if(bearerToken)//Wait for bearer token
        {
          updateLoading(true);
          var elabelRequest = axios.get(`${ config.BASE_URL }/elabels/${ elabelStockType }/${ elabelId }`,
          {headers: {
              Authorization: bearerToken
            }
          });
      
          var elabelImageRequest = axios.get(`${ config.BASE_URL }/getElabelImage/${ elabelStockType }/${ elabelId }`,
          {headers: {
              Authorization: bearerToken
            }
          });
          trackPromise(
            axios.all([elabelRequest, elabelImageRequest])
            .then(axios.spread((...responses) => {
                updateElabel(responses[0].data.Item);
                updateElabelImage(responses[1].data);
                updateLoading(false);
            }))
            .catch(function (error,response) {
              console.log('Error:' +  error.message + "  " + response);
            }));
        }
    }, [bearerToken,elabelStockType,elabelId])

    return(
      !loading &&
      <Container  fluid >
          <Button variant={'primary'} onClick={() => navigate("/elabels")}><BsChevronLeft /></Button>
          <Row className="mt-5">
          <Col xs={8}>
            <Alert variant={'primary'} className="d-flex justify-content-center">{t('elabels.identification')}</Alert>

            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>{t('elabels.stock')}</th>
                  <th>{t('elabels.elabelId')}</th>
                  <th>{t('elabels.creationDate')}</th>
                  <th>{t('elabels.status')}</th>
                  <th>{t('elabels.isActive')}</th>
                </tr>
                <tr>
                  <td>{elabel.SK && vehicleStocksList[elabel.SK.split("#")[2]]}</td>
                  <td>{elabel.thingName.toUpperCase()}</td>
                  <td>{elabel.creationDate}</td>
                  <td>{elabel.status}</td>
                  <td>{elabel.isActive}</td>
                </tr>
              </tbody>
            </Table>

            <Alert variant={'primary'} className="d-flex justify-content-center">{t('elabels.connection')}</Alert>

            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>{t('elabels.associatedVehicle')}</th>
                  <th>{t('elabels.lastUpdateDate')}</th>
                  <th>{t('elabels.lastUpdateType')}</th>
                  <th>{t('elabels.batteryLeft')}</th>
                </tr>
                <tr>
                  <td>{(elabel.associatedVehicleFileNumber === "noVehicleAssigned")?t('elabels.noVehicleAssigned') : elabel.associatedVehicleFileNumber }</td>
                  <td>{(new Date(elabel.lastUpdateDate)).toLocaleString(undefined, { timeZone: 'UTC' })}</td>
                  <td>{elabel.lastUpdateType}</td>
                  <td>{elabel.batteryLeft}</td>
                </tr>
              </tbody>
            </Table>

            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>{t('elabels.temperature')}</th>
                  <th>{t('elabels.lattitude')}</th>
                  <th>{t('elabels.longitude')}</th>
                  <th>{t('elabels.dimensions')}</th>
                </tr>
                <tr>
                  <td>{elabel.temperature}</td>
                  <td>{elabel.lattitude}</td>
                  <td>{elabel.longitude}</td>
                  <td>{elabel.dimensions}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col>
          <Alert variant={'success'} className="d-flex justify-content-center">{t('elabels.contentDisplay')}</Alert>
            <Figure.Image
              width={535}
              height={400}
              alt="171x180"
              src={`data:image/bmp;base64,${elabelImage}`}
            />
          </Col>
        </Row>
      </Container>
    )

}

export default DisplayElabel;