import { React } from 'react'
import { useTranslation } from 'react-i18next';
import {BrowserRouter, Routes, Route, Link} from "react-router-dom";
import {Navbar, Nav, Container, Button} from 'react-bootstrap';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import './styles/amplify.css';
import config from './config/aws';
import Dashboard from "./components/Dashboard";
import Vehicles from "./components/Vehicles";
import Elabels from "./components/Elabels";
import NewVehicle  from './forms/NewVehicle';
import ModifyVehicle  from './forms/ModifyVehicle';
import DisplayVehicle from './components/DisplayVehicle';
import DisplayElabel from './components/DisplayElabel';
import ModifyElabel from './forms/ModifyElabel';
import DefaultPoster from './posters/DefaultPoster';
import { I18nextProvider  } from 'react-i18next'
import i18n from './config/i18n';
import {materialTableLocalization} from './config/materialTable';

Amplify.configure(config);

function App() {
  const t = useTranslation()[0];

  //translate all parameters
  for (var paginationKey in materialTableLocalization['pagination']) {
    if (materialTableLocalization['pagination'].hasOwnProperty(paginationKey)) {
      materialTableLocalization['pagination'][paginationKey] = t(materialTableLocalization['pagination'][paginationKey]);
    }
  }

  for (var toolbarKey in materialTableLocalization['toolbar']) {
    if (materialTableLocalization['toolbar'].hasOwnProperty(toolbarKey)) {
      materialTableLocalization['toolbar'][toolbarKey] = t(materialTableLocalization['toolbar'][toolbarKey]);
    }
  }

  materialTableLocalization.body.emptyDataSourceMessage = t(materialTableLocalization.body.emptyDataSourceMessage);

  for (var filterRowKey in materialTableLocalization['body']['filterRow']) {
    if (materialTableLocalization['body']['filterRow'].hasOwnProperty(filterRowKey)) {
      materialTableLocalization['body']['filterRow'][filterRowKey] = t(materialTableLocalization['body']['filterRow'][filterRowKey]);
    }
  }

  return (
  <Authenticator>
  {({ signOut, user }) => (
  <BrowserRouter>
  <I18nextProvider i18n={i18n}>
    <Container fluid>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light" >
        <Navbar.Brand as={Link} to="/dashboard">etiQcar</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav.Link as={Link} to="/vehicles" className="nav-link ">{t('navbar.vehicles')}</Nav.Link>
            <Nav.Link as={Link} to="/elabels" className="nav-link ">{t('navbar.elabels')}</Nav.Link>
          <Nav className="ml-auto">
          <Button onClick={signOut} variant="primary">{t('navbar.signout')}</Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Routes>
        <Route exact path="/" element= {<Dashboard />} />
        <Route path="/dashboard" element= {<Dashboard />} />
        <Route exact path="/vehicles" element= {<Vehicles/>} />
        <Route exact path="/vehicles/:vehicleStockType/:vehicleId" element= {<DisplayVehicle/>}/>
        <Route path="/vehicles/:vehicleStockType/:vehicleId/modify" element= {<ModifyVehicle/>}/>
        <Route path="/new_vehicle" element= {<NewVehicle/>}/>
        <Route exact path="/elabels" element= {<Elabels />}/>
        <Route exact path="/elabels/:elabelStockType/:elabelId" element= {<DisplayElabel/>}/>
        <Route exact path="/elabels/:elabelStockType/:elabelId/modify" element= {<ModifyElabel/>}/>
        <Route exact path="/elabels/:elabelStockType/:elabelId/defaultposter" element= { <DefaultPoster/>}/>
        <Route render={function () {
          return <p>This page does not exist</p>
        }} />
      </Routes>
    </Container>
    </I18nextProvider>
  </BrowserRouter>
  )}
  </Authenticator>
  )
}

export default App;